import React from 'react'

const ZyvlyLogo = ({ width = '174', height = '55' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 174 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_10_11291)">
        <path d="M52.711 54.5269C51.5661 54.2082 50.6136 53.7254 49.8632 53.0978L51.5854 49.9983C52.0183 50.5969 52.6629 51.0701 53.5095 51.4273C54.3562 51.7846 55.1836 51.9584 55.9725 51.9584C57.3579 51.9584 58.6952 51.5722 59.9748 50.7997C61.264 50.0272 62.303 49.0134 63.1208 47.7677C63.929 46.5125 64.3331 45.18 64.3331 43.7509C64.3331 42.3218 64.0156 41.0569 63.3806 39.8209L49.6804 14.2231H56.7999L68.4219 37.1463L79.4571 14.2231H83.4883L69.0184 44.282C67.2771 47.8933 65.3817 50.5776 63.3229 52.3446C61.264 54.1117 59.0127 55 56.5594 55C55.1355 55 53.8559 54.8359 52.7014 54.5269H52.711Z" fill="url(#paint0_linear_10_11291)" />
        <path d="M86.7593 14.2231H93.7633L105.443 36.9049L116.95 14.2231H120.808L105.982 43.9923H102.48L86.7593 14.2231Z" fill="url(#paint1_linear_10_11291)" />
        <path d="M126.85 2.7326H133.44V43.3936H126.85V2.7326Z" fill="url(#paint2_linear_10_11291)" />
        <path d="M142.445 54.5269C141.3 54.2082 140.348 53.7254 139.597 53.0978L141.319 49.9983C141.752 50.5969 142.397 51.0701 143.244 51.4273C144.09 51.7846 144.918 51.9584 145.707 51.9584C147.092 51.9584 148.429 51.5722 149.709 50.7997C150.998 50.0272 152.037 49.0134 152.855 47.7677C153.673 46.5221 154.067 45.18 154.067 43.7509C154.067 42.3218 153.75 41.0569 153.115 39.8209L139.415 14.2231H146.534L158.156 37.1463L169.191 14.2231H173.222L158.753 44.282C157.011 47.8933 155.116 50.5776 153.057 52.3446C150.998 54.1117 148.747 55 146.293 55C144.87 55 143.59 54.8359 142.436 54.5269H142.445Z" fill="url(#paint3_linear_10_11291)" />
        <path d="M33.5942 34.9157C25.0894 30.194 17.941 34.2302 13.3807 38.0732C13.3807 38.0732 35.4511 15.884 44.5236 6.16046C47.7851 3.18644 51.5276 1.50632 51.5276 1.50632L47.3521 1.68978C42.6571 4.04582 31.9876 9.94557 19.6247 4.57689C23.4923 6.25702 33.8829 6.56601 41.3487 2.385C38.9531 2.77124 36.2207 2.85814 33.1805 2.385C31.6989 2.1243 30.0345 1.7284 28.1296 1.12008C28.1103 1.12008 28.0815 1.11043 28.0622 1.10077C28.0045 1.08146 27.9468 1.06215 27.889 1.04283C10.9755 -4.19066 2.10503 11.9347 2.10503 11.9347C2.10503 11.9347 8.74345 5.03072 18.7492 10.5732C27.2541 15.2949 34.4024 11.2588 38.9627 7.41573C38.9627 7.41573 16.9886 29.5084 7.87757 39.2609C4.61608 42.2832 0.777344 44.0212 0.777344 44.0212L4.95282 43.8378C9.64782 41.4817 20.3655 35.5434 32.7284 40.912C28.8608 39.2319 18.4702 38.9229 10.9947 43.1039C13.3903 42.7177 16.1227 42.6308 19.1629 43.1039C20.6445 43.3646 22.3089 43.7605 24.2139 44.3689C24.2331 44.3689 24.262 44.3785 24.2812 44.3882C24.3389 44.4075 24.3967 44.4268 24.4544 44.4461C41.3872 49.6699 50.2576 33.5446 50.2576 33.5446C50.2576 33.5446 43.6192 40.4486 33.6135 34.9061L33.5942 34.9157Z" fill="url(#paint4_linear_10_11291)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_10_11291" x1="3.94265" y1="47.4394" x2="178.65" y2="2.77733" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A72471" />
          <stop offset="1" stop-color="#F0867B" />
        </linearGradient>
        <linearGradient id="paint1_linear_10_11291" x1="5.251" y1="52.5667" x2="179.958" y2="7.91421" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A72471" />
          <stop offset="1" stop-color="#F0867B" />
        </linearGradient>
        <linearGradient id="paint2_linear_10_11291" x1="5.88617" y1="55.0579" x2="180.593" y2="10.4055" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A72471" />
          <stop offset="1" stop-color="#F0867B" />
        </linearGradient>
        <linearGradient id="paint3_linear_10_11291" x1="9.48428" y1="69.1169" x2="184.191" y2="24.4548" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A72471" />
          <stop offset="1" stop-color="#F0867B" />
        </linearGradient>
        <linearGradient id="paint4_linear_10_11291" x1="-0.608066" y1="29.634" x2="174.099" y2="-15.0186" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A72471" />
          <stop offset="1" stop-color="#F0867B" />
        </linearGradient>
        <clipPath id="clip0_10_11291">
          <rect width="172.464" height="55" fill="white" transform="translate(0.767822)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default ZyvlyLogo