import { useCallback, useRef, useState } from 'react'

const useDeleteModalState = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const id = useRef(null);

  const openConfirmModal = useCallback(() => {
    setOpenConfirm(true);
  }, [])

  const closeConfirm = useCallback(() => {
    id.current = null;
    setOpenConfirm(false);
  }, [])

  const setId = useCallback((value) => {
    id.current = value;
  }, [openConfirm])


  return {
    openConfirmModal,
    closeConfirm,
    id: id.current,
    setId,
    openConfirm
  }
}

export default useDeleteModalState