import { styled } from '@mui/material/styles'
import { Dialog, Stack } from '@mui/material';
import ModalHeader from './ModalHeader';

const MuiDialog = styled(Dialog)(({ maxWidth }) => ({
  '& .MuiPaper-root': {
    padding: '0px!important',
    width: maxWidth,
    maxWidth: maxWidth,
    boxShadow: "0px 8px 8px -4px #1018280A",
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    maxHeight: 'calc(100% - 60px)'
  },
}));


const ModalBox = ({ maxWidth = "480px", open, handleClose, headerTitle, children }) => {
  return (
    <MuiDialog onClose={handleClose} open={open} maxWidth={maxWidth}>
      {headerTitle ? <ModalHeader title={headerTitle} handleClose={handleClose} /> : null}
      <Stack p={2}>
        {children}
      </Stack>
    </MuiDialog>
  )
}

export default ModalBox