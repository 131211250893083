import { createSlice } from "@reduxjs/toolkit";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
import { localStorageService } from "shared/services/localStorageService";

const initialState = {
  userInfo: {},
  sidebar: true,
  isFirstLogin: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      // console.log(state?.user, "user")
      // debugger;
      const { healthie_api_token, ...rest } = action.payload || {};
      if (!localStorageService.getHealthieToken()) {
        localStorageService.setHealthieToken(healthie_api_token);
      }
      state.userInfo = { ...state?.userInfo, ...rest };
    },
    resetUser: (state) => {
      state.userInfo = {};
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    setFirstLogin: (state, action) => {
      state.isFirstLogin = action.payload;
    },
  },
});

export const { setUser, resetUser, setSidebar, setFirstLogin } = userSlice.actions;

export default userSlice.reducer;
