import { useCallback } from 'react'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, IconButton, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'
import { upload } from '@testing-library/user-event/dist/upload';

const UploadWrapper = styled('div')(({ theme }) => ({
  padding: '12px 24px',
  border: `1px solid #DEDFE4`,
  textAlign: 'center',
  boxShadow: 'none',
  // height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  // width: '100%',
  // maxWidth: '100%'
}));


const UploadRecordInput = ({ label, name, multiUpload = false }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useFormContext();
  const myfiles = watch(name);


  const preventDefault = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (files && files[0]) {
        const uploadedFiles = getValues(name);
        if (multiUpload) {
          setValue(name, [...uploadedFiles, ...files])
        } else {
          setValue(name, [...files])
        }
      }
    },
    [],
  );
  const { uploaded_file } = errors ?? {};
  const errorMessage = Array.isArray(uploaded_file) ? uploaded_file[0]?.message ?? '' : uploaded_file?.message ?? '';
  // console.log(typeof [name], "uploaded_file")
  // const [fileError = {}] = uploaded_file ?? [];
  // const { message: errorMessage } = fileError;
  return (
    <FormControl
      fullWidth
    // margin="normal"
    >
      <Typography fontWeight={600} variant="caption" color={theme => theme.palette.text.labelColor} gutterBottom>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <UploadWrapper
              onDragOver={preventDefault}
              onDragLeave={preventDefault}
              onDrop={handleDrop}
            >
              <input
                // accept={acceptTypes[getAssetStoreKey[type]]?.join(', ')}
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                // onChange={handleChange}
                onClick={(event) => {
                  event.target.value = null
                }}
                {...field}
                value={value?.fileName}
                onChange={(event) => {
                  const files = getValues(name);
                  if (multiUpload) {
                    onChange([...files, ...event.target.files]);
                  } else {
                    onChange([...event.target.files]);
                  }
                }}
              />
              <label htmlFor="raised-button-file" style={{ cursor: "pointer" }}>
                <IconButton aria-label="upload picture" component="span"
                  sx={{ p: 1, borderRadius: '1000px', backgroundColor: '#F7F8F9', mb: 1.5 }}>
                  <FileUploadOutlinedIcon />
                </IconButton>
                <Typography variant='body2' fontWeight={600}>
                  Click to Upload <Typography display="contents" color="#49546B" variant="body2" >or drag and drop</Typography>
                </Typography>
                <Typography color='#8990A0' fontWeight={500} variant='caption'>
                  Images, PDfs, Texts, Videos, Presentations OR Spreadsheets
                </Typography>
              </label>
            </UploadWrapper>
          );
        }}
      />
      <Typography
        color="error"
        fontSize={10}
        margin="auto"
        p={0}
        mt={0.5}
        display="contents"
      >
        {errorMessage}
      </Typography>
      {(multiUpload ? myfiles : myfiles.slice(0, 1)).map((item) =>
        <Typography fontWeight={500} variant='caption'>
          {item?.name}
        </Typography>
      )

      }
    </FormControl>
  )
}

export default UploadRecordInput