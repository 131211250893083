import { Badge, IconButton, Menu, Stack, Typography, Divider } from '@mui/material'
import { useState } from 'react'

import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DropdownNotificationListing from './DropdownNotificationListing';
import Link from 'components/Link';


const WrapperStyle = {
  top: "12px",
  '& .MuiPaper-root': {
    // boxShadow: "0px 8px 8px -4px #1018280A",
    width: '460px',
    // height: '465px',
    overflowY: 'auto',
    radius: '16px',
    // boxShadow: 'none',
    '& .MuiList-root': {
      height: '465px',
      padding: '0px!important',
      display: 'flex',
      flexDirection: 'column',
      gap: "12px",
    }
  }
}
const iconButtonStyle = {
  border: `1px solid #DDDFE4`,
  color: '#9E9E9E',
  borderRadius: '50%',
}
const NotificationsDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={iconButtonStyle} onClick={handleClick}>
        <NotificationsOutlinedIcon />
      </IconButton>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock
        disableAutoFocusItem
        sx={WrapperStyle}
      >
        <div>
          <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography fontSize={18} color="#1E2B49" fontWeight={600}>Notifications</Typography>
              {/* <Badge badgeContent={6} color="primary" /> */}
            </Stack>
            <IconButton onClick={handleClose}> <CloseOutlinedIcon sx={{ color: '#1E2B49' }} /> </IconButton>
          </Stack>
          <Divider />
        </div>
        {/* <Stack display="flex" flexDirection="row-reverse" px="26px" >
          <Button sx={{ fontWeight: 600, padding: 0 }}>Mark all as read</Button>
        </Stack> */}
        {/* <Stack px={3}> */}
        <DropdownNotificationListing gap={0.5} />
        {/* </Stack> */}
        {/* <Stack direction="column" gap={0.5} px={3}>
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
        </Stack> */}
        <div onClick={handleClose}>
          <Link
            textAlign="center"
            to="/settings?tab=notifications-tab"
            color={theme => theme.palette.text.appGrey}
            variant="body2" fontWeight={600}
          >
            See all
          </Link>
        </div>
      </Menu>
    </>
  )
}

export default NotificationsDropdown