import { Stack } from '@mui/material'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import ModalBox from 'components/ModalBox'
import SelectInput from 'components/SelectInput';
import InputField from 'components/InputField';
import UploadRecordInput from 'components/PatientHealthRecords/UploadRecordInput';
import ActionButtons from 'components/ModalBox/ActionButtons';

const schema = yup.object().shape({
  category: yup.string().required("Category is required"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  uploaded_file: yup.array()
    // .min(1, 'File is required')
    .max(1, 'File is required')
    .of(yup.mixed().required('File is required')),
});

const Feedback = ({ open, handleClose }) => {

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      uploaded_file: [],
    }
  });

  const onSubmit = async (values) => {
    console.log(values, "values");
    // onSignIn(values);
  };

  return (
    <ModalBox
      maxWidth='600px'
      headerTitle="Support and Feedback"
      open={open}
      handleClose={handleClose}
    >
      <FormProvider {...methods}>
        <Stack
          component='form'
          onSubmit={methods.handleSubmit(onSubmit)}
          direction="column"
          gap={1}
        >
          <SelectInput
            name="category"
            label="Feedback Category"
            options={[]}
            placeholder="Select a category"
          />

          <InputField
            name="title"
            label="Title"
            placeholder="Add title"
          />

          <InputField
            name="description"
            label="Description"
            placeholder="Add description here"
            className="textArea"
            minRows={2}
            maxRows={2}
            multiline
          />

          <UploadRecordInput
            name="uploaded_file"
            label="Attach files (optional)"
          />

          <ActionButtons
            confirmText="Submit Feedback"
            isLoading={false}
            handleConfirm={onSubmit}
            handleCancel={handleClose}
            fullWidth={true}
            sx={{ p: '12px 0px' }}
          />

        </Stack>
      </FormProvider>

    </ModalBox>
  )
}

export default Feedback