import Cookies from "js-cookie";
export const localStorageService = (() => {
  const _setToken = (value) => {
    Cookies.set("token", value, { expires: 1 });
    localStorage.removeItem("isLogout");
  };

  const _setHealthieToken = (value) => {
    Cookies.set("healthieToken", value, { expires: 1 });
  };

  const _setRefreshToken = (value) => {
    Cookies.set("refreshToken", value, { expires: 7 });
  };

  const _setFirstLogin = (value) => {
    Cookies.set("isFirstLogin", value, { expires: 7 });
  };

  const _getFirstLogin = () =>
    typeof window !== "undefined" ? Cookies.get("isFirstLogin") : false;

  const _getToken = () =>
    typeof window !== "undefined" ? Cookies.get("token") : false;

  const _getRefreshToken = () =>
    typeof window !== "undefined" ? Cookies.get("refreshToken") : false;

  const _getHealthieToken = () =>
    typeof window !== "undefined" ? Cookies.get("healthieToken") : false;

  const _logout = () => {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    Cookies.remove("healthieToken");
    Cookies.remove("isFirstLogin");
    localStorage.setItem("isLogout", "true");
  };


  const _removeToken = () => {
    Cookies.remove("token")
  }

  const _removeRefreshToken = () => {
    Cookies.remove("refreshToken");
  }

  const _removehealthieToken = () => {
    Cookies.remove("healthieToken");
  }

  const _removeFirstLogin = () => {
    Cookies.remove("isFirstLogin");
  }

  return {
    setToken: _setToken,
    getToken: _getToken,
    logout: _logout,
    removeToken: _removeToken,
    setRefreshToken: _setRefreshToken,
    removeRefreshToken: _removeRefreshToken,
    getRefreshToken: _getRefreshToken,
    setHealthieToken: _setHealthieToken,
    getHealthieToken: _getHealthieToken,
    removehealthieToken: _removehealthieToken,
    setFirstLogin: _setFirstLogin,
    getFirstLogin: _getFirstLogin,
    removeFirstLogin: _removeFirstLogin
  };
})();
