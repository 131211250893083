import {
  Outlet, Navigate
  // , useLocation
} from "react-router-dom";
import Layout from "components/Layout";
import useAuth from "shared/hooks/useAuth";
import Loader from "components/Loader";
// import { routes } from "shared/routes";

const ProtectedRoute = () => {
  const { isLoading, authenticated } = useAuth();
  // const { pathname } = useLocation();
  // const path = `/${pathname.split("/")[1]}`;
  if (isLoading) {
    return <Loader pageLoader />
  }

  if (
    authenticated
    // && routes[role]?.includes(path)
  ) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
  // if (authenticated 
  //   // && !routes[role]?.includes(path)
  //   ) {
  //   return <Navigate to={routes[role][0]} />
  // }

  return <Navigate to="/login" />;
};

export default ProtectedRoute;
