import { Stack, Divider } from "@mui/material"
import ZyvlyLogo from "assets/logos/ZyvlyLogo"
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { appBorderColor } from "theme/colors";
import ProfileDropdown from "./ProfileDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import SupplementCart from "./SupplemendCart";
import Link from "components/Link";

const iconButtonStyle = {
  border: `1px solid #DDDFE4`,
  color: '#9E9E9E',
  borderRadius: '50%',
}

const Header = () => {
  return (
    <Stack
      height="60px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      borderBottom={`1px solid ${appBorderColor}`}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Link linHeight={0}
          to={"/appointments"}
        >
          <ZyvlyLogo height="26" width="81" />
          {/* add breadcrumb here  */}
        </Link>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <NotificationsDropdown />
        {/* <IconButton sx={iconButtonStyle}>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton sx={iconButtonStyle}>
          <ShoppingCartOutlinedIcon />
        </IconButton> */}
        <SupplementCart />
        <Divider orientation="vertical" sx={{ borderBottomWidth: '40px', bordercolor: '#c4c4c4', borderRightWidth: '2px' }} />
        <ProfileDropdown />
      </Stack>
    </Stack>
  )
}

export default Header