import { useState } from "react";
import { TextField, Typography, FormControl, IconButton } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Controller, useFormContext } from "react-hook-form";

const InputField = ({ label, name, placeholder, autoFocus, type, isPasswordField, intakeField, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(p => !p);
  }

  return (
    <FormControl
      fullWidth
    // margin="normal"
    >
      <Typography
        fontWeight={600}
        variant={intakeField ? 'body1' : "caption"} color={theme => intakeField ? "#1E1E1E" : theme.palette.text.labelColor}
        gutterBottom
      >
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => {
          return (
            <TextField
              {...field}
              type={show ? "text" : type}
              placeholder={placeholder}
              autoFocus={autoFocus}
              InputProps={{
                endAdornment: isPasswordField ? (
                  <IconButton
                    edge="end"
                    onClick={handleClick}
                    sx={{ color: '#6B7280' }}
                  >
                    {show ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                ) : null
              }}
              {...props}
            />
          );
        }}
      />
      <Typography
        color="error"
        fontSize={10}
        margin="auto"
        p={0}
        mt={0.5}
        display="contents"
      >
        {errors?.[name]?.message}
      </Typography>
    </FormControl>
  );
};

export default InputField;
