import { Avatar, Badge, Stack, Typography } from '@mui/material'
import { appointmentIcon, messageIcon, presscriptionIcon, treatmentIcon } from 'assets/svgIcons'
// import { appointment } from 'assets/svgIcons'
import { appWhite } from 'theme/colors'
import { notificationTimeDifference } from 'utils/helperFunctions'

const notificationIcons = {
  appointment: appointmentIcon,
  prescription: presscriptionIcon,
  treatment: treatmentIcon,
  message: messageIcon,
}

const NotificationCard = ({ created_at, message, read, title, notification_type }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      p="16px 12px"
      borderRadius="8px"
      sx={{ backgroundColor: appWhite }}
      position="relative"
    >
      <Stack direction="row" gap={2}>
        <Avatar
          sx={{ height: 36, width: 36 }}
        >
          {notificationIcons[notification_type]}
        </Avatar>
        <div>
          <Typography fontWeight={600} gutterBottom>{title}</Typography>
          <Typography color="#1E2B49" variant="body2" >{message}</Typography>
        </div>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2} position="absolute" top={18} right={16}>
        <Typography color="#757575" variant="caption" >{notificationTimeDifference(created_at)}</Typography>
        {!read ? <Badge variant="dot" color='success' /> : null}
      </Stack>

    </Stack>
  )
}

export default NotificationCard