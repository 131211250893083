import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Typography, TextField, Autocomplete } from '@mui/material'

const SelectInput = ({ name, options, label, placeholder, disabled = false, isObject, className = '' }) => {
  const { control, formState: { errors }, getValues } = useFormContext();
  // const intialValue = getValues(name);
  return (
    <FormControl fullWidth>
      <Typography fontWeight={600} variant="caption" color={theme => theme.palette.text.labelColor} gutterBottom>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...field } }) =>
          <Autocomplete
            {...field}
            disableClearable
            disablePortal
            disabled={disabled}
            options={options}
            getOptionLabel={(option) => typeof option === 'object' ? option?.label : option}
            onChange={(_, selectedOption) => {
              if (isObject) onChange(selectedOption)
              else onChange(selectedOption?.value)
            }}
            renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
            className={className}
            getOptionDisabled={(option) => option?.noInStock}
          />
        }
      />
      <Typography
        color="error"
        fontSize={10}
        margin="auto"
        p={0}
        mt={0.5}
        display="contents"
      >
        {errors?.[name]?.message}
      </Typography>
    </FormControl>
  )
}

export default SelectInput