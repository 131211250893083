import { current } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";

export const supplementStore = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "supplementStore",
  tagTypes: ["Products", "Cart", "Collections"],
  endpoints: (build) => ({
    getProducts: build.query({
      query({ query, cursor, collection }) {
        return {
          url: `/api/supplement_store/products/?`,
          method: "GET",
          params: {
            first: 25,
            query,
            after: cursor,
            collection_id: collection
          }
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, response, { arg }) => {
        if (arg?.query) {
          if (arg?.cursor && arg?.collection === currentCache?.collectionId) {
            return {
              ...response,
              products: [...currentCache?.products ?? [], ...response?.products ?? []],
            }
          }
          return {
            ...response,
          };
        }
        else if (arg?.cursor && arg?.collection === currentCache?.collectionId) {
          return {
            ...response,
            products: [...currentCache?.products ?? [], ...response?.products ?? []],
          }
        } else {
          return response;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      transformResponse: (response, _, { collection }) => ({
        products: response?.data?.products?.edges ?? [],
        hasNextPage: response?.data?.products?.pageInfo?.hasNextPage ?? false,
        cursor: response?.data?.products?.edges?.at(-1)?.cursor ?? null,
        collectionId: collection ?? ''
      }),
    }),
    getProductById: build.query({
      query(id) {
        return {
          url: `/api/supplement_store/products/${id}/`,
          method: "GET"
        };
      },
      transformResponse: (response) => response?.data?.product ?? {},
    }),
    getCartById: build.query({
      query(id) {
        return {
          url: `/api/supplement_store/carts/`,
          method: "GET",
          params: { cart_id: id },
        };
      },
      transformResponse: (response) => response?.data?.cart ?? {},
      providesTags: ["Cart"],
    }),
    addToCart: build.mutation({
      query(body) {
        return {
          url: `/api/supplement_store/carts/add_to_cart/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Cart"],
    }),
    removeFromCart: build.mutation({
      query(body) {
        return {
          url: `/api/supplement_store/carts/remove_from_cart/`,
          method: "POST",
          body,
        };
      },
      // onQueryStarted: async ({ line_id }, { queryFulfilled, dispatch }) => {
      //   const patchResult = dispatch(
      //     supplementStore.util.updateQueryData('getCartById', undefined, (draft) => {
      //       debugger;
      //     })
      //   )
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     patchResult.undo()
      //   }
      // },
      invalidatesTags: ["Cart"],
    }),
    updateCart: build.mutation({
      query(body) {
        return {
          url: `/api/supplement_store/carts/update_cart_item/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Cart"],
    }),
    getCollections: build.query({
      query() {
        return {
          url: `/api/supplement_store/collections/`,
          method: "GET"
        };
      },
      transformResponse: (response) => {
        const transformedData = response?.data?.collections?.edges?.map(({ node }) =>
          ({ label: node?.title, value: node?.id })
        ) ?? [];
        transformedData.unshift({ label: 'All', value: '' });
        return transformedData;
      },
      providesTags: ["Collections"],
      keepUnusedDataFor: 300,
    }),

    getOrders: build.query({
      query({ pageSize = '', cursor = '', startCursor = '', last = '' }) {
        console.log(!pageSize && !last)
        return {
          url: `/api/supplement_store/orders/`,
          method: "GET",
          params: {
            first: pageSize,
            after: cursor,
            before: startCursor,
            last
          }
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, response) => {
        return {
          ...response,
          totalCount: currentCache?.totalCount,
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      transformResponse: (response) => ({
        products: response?.data?.customer?.orders?.edges ?? [],
        hasNextPage: response?.data?.customer?.orders?.pageInfo?.hasNextPage ?? false,
        cursor: response?.data?.customer?.orders?.pageInfo?.endCursor ?? null,
        startCursor: response?.data?.customer?.orders?.pageInfo?.startCursor ?? null,
        hasPreviousPage: response?.data?.customer?.orders?.pageInfo?.hasPreviousPage ?? false,
        totalCount: response?.data?.customer?.orders?.totalCount ?? 0,
      }),
    }),

    getSupplmentOrders: build.query({
      query({ pageSize = 25, cursor = '', startCursor = '' }) {
        return {
          url: `/api/supplement_store/orders/`,
          method: "GET",
          params: {
            first: pageSize,
            after: cursor,
            before: startCursor
          }
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, response) => {
        return {
          ...response,
          products: [...currentCache?.products ?? [], ...response?.products ?? []],
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.cursor !== previousArg?.cursor
      },
      transformResponse: (response) => ({
        products: response?.data?.customer?.orders?.edges ?? [],
        hasNextPage: response?.data?.customer?.orders?.pageInfo?.hasNextPage ?? false,
        cursor: response?.data?.customer?.orders?.pageInfo?.endCursor ?? null,
        startCursor: response?.data?.customer?.orders?.pageInfo?.startCursor ?? null,
        hasPreviousPage: response?.data?.customer?.orders?.pageInfo?.hasPreviousPage ?? false,
        totalCount: response?.data?.customer?.orders?.totalCount ?? 0,
      }),
    }),
    reorderSupplments: build.mutation({
      query(id) {
        return {
          url: `/api/supplement_store/orders/re_order/`,
          method: "POST",
          body: {
            order_id: id
          }
        };
      },
      invalidatesTags: ["Cart"],
      keepUnusedDataFor: 0,
    }),

    getOrderById: build.query({
      query(id) {
        return {
          url: `/api/supplement_store/orders/?`,
          method: "GET",
          params: {
            order_id: id
          }
        };
      },
      transformResponse: (response) => ({
        ...response?.data?.node,
        error: response?.data?.node === null
      })
    }),

  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useGetCartByIdQuery,
  useAddToCartMutation,
  useRemoveFromCartMutation,
  useUpdateCartMutation,
  useGetCollectionsQuery,
  useGetOrdersQuery,
  useReorderSupplmentsMutation,
  useGetOrderByIdQuery,
  useGetSupplmentOrdersQuery,
} = supplementStore;
