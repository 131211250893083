import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities'
import * as ActionCable from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { localStorageService } from 'shared/services/localStorageService';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HEALTHIE_GRAPHQL_URI,
  // headers: {
  //   Authorization: 'Basic',
  //   Authorizationsource: 'Web',
  // },
})

const cable = ActionCable.createConsumer(
  `wss://${process.env.REACT_APP_HEALTHIE_WEB_SOCKETS_URL}/subscriptions?token=${localStorageService.getHealthieToken()}`
)

const wsLink = new ActionCableLink({ cable })

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    if (!('operation' in definition)) {
      return false
    }

    const { kind, operation } = definition
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

// Create a middleware that adds the authorization header
const authMiddleware = new ApolloLink((operation, forward) => {
  // Add the authorization headers to the request
  operation.setContext({
    headers: {
      // Authorization: `Basic ${process.env.REACT_APP_HEALTHIE_AUTHURIZATION_KEY}`,
      Authorization: `Bearer ${localStorageService.getHealthieToken()}`,
      Authorizationsource: "Web"
    }
  });

  // Call the next link in the middleware chain
  return forward(operation);
});

export const testClient = new ApolloClient({
  link: concat(authMiddleware, link), // Combine the auth middleware and the HttpLink
  cache: new InMemoryCache(
    {
      typePolicies: {
        Query: {
          fields: {
            // custom configuration if needed
          },
        },
      },
    }
  )
});