import React, { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import theme from "theme";
import { ThemeProvider } from "@emotion/react";
import ProtectedRoute from "components/Layout/ProtectedRoute";
import UnProtectedRoutes from "components/Layout/UnProtectedRoutes";
import Loader from 'components/Loader';

const _404 = lazy(() => import('containers/_404'));
const ForgetPassword = lazy(() => import('containers/Auth/ForgetPassword'));
const ResetPassword = lazy(() => import('containers/Auth/ResetPassword'));
const Login = lazy(() => import('containers/Auth/Login'));
const Register = lazy(() => import('containers/Auth/Register'));
const Landing = lazy(() => import('containers/Landing'));
const AccountSettings = lazy(() => import('containers/AccountSettings'));
const PatientHealthRecords = lazy(() => import('containers/PatientHealthRecords'));
const CreateAppointment = lazy(() => import('containers/CreateAppointment'));
const HealthArticles = lazy(() => import('containers/HealthArticles'));
// const DetailConsultationView = lazy(() => import('containers/DetailConsultationView'));
const OrderTracking = lazy(() => import('containers/OrderTracking'));
const OrderDetail = lazy(() => import('containers/OrderDetail'));
const SupplementStore = lazy(() => import('containers/SupplementStore'));
const SupplementDetail = lazy(() => import('containers/SupplementDetail'));
const Appointments = lazy(() => import('containers/Appointments'));
const RescheduleAppointment = lazy(() => import('containers/RescheduleAppointment'));
const Chat = lazy(() => import('containers/Chat'));
const PatientAddressSettings = lazy(() => import('containers/PatientAddressSettings'));




function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='*' element={<Suspense fallback={<Loader pageLoader />}><_404 /></Suspense>} />
        <Route path='/' element={<Suspense fallback={<Loader pageLoader />}><Landing /></Suspense>} />
        <Route element={<Suspense fallback={<Loader pageLoader />}><UnProtectedRoutes /></Suspense>}>
          <Route path='/forget-password' element={<Suspense fallback={<Loader pageLoader />}><ForgetPassword /></Suspense>} />
          <Route path='/reset-password' element={<Suspense fallback={<Loader pageLoader />}><ResetPassword /></Suspense>} />
          <Route index path='/login' element={<Suspense fallback={<Loader pageLoader />}><Login /></Suspense>} />
          <Route path='/register' element={<Suspense fallback={<Loader pageLoader />}><Register /></Suspense>} />
        </Route>
        <Route element={<Suspense fallback={<Loader />}><ProtectedRoute /></Suspense>}>
          {/* <Route path='/dashboard' element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>} /> */}
          <Route path='/appointments' element={<Suspense fallback={<Loader />}><Appointments /></Suspense>} />
          <Route path='/supplement-store' element={<Suspense fallback={<Loader />}><SupplementStore /></Suspense>} />
          <Route path='/settings' element={<Suspense fallback={<Loader />}><AccountSettings /></Suspense>} />
          <Route path='/health-records' element={<Suspense fallback={<Loader />}><PatientHealthRecords /></Suspense>} />
          <Route path='/new-appointment' element={<Suspense fallback={<Loader pageLoader />}><CreateAppointment /></Suspense>} />
          <Route path='/health-articles' element={<Suspense fallback={<Loader />}><HealthArticles /></Suspense>} />
          <Route path='/order-tracking' element={<Suspense fallback={<Loader />}><OrderTracking /></Suspense>} />
          {/* <Route path='/consultation/:id' element={<Suspense fallback={<Loader />}><DetailConsultationView /></Suspense>} /> */}
          <Route path='/order' element={<Suspense fallback={<Loader />}><OrderDetail /></Suspense>} />
          <Route path='/supplement/:id' element={<Suspense fallback={<Loader />}><SupplementDetail /></Suspense>} />
          <Route path='/reschedule-appointment/:id' element={<Suspense fallback={<Loader />}> <RescheduleAppointment /> </Suspense>} />
          <Route path='/chat' element={<Suspense fallback={<Loader />}> <Chat /> </Suspense>} />
          <Route path='/personal-info' element={<Suspense fallback={<Loader />}> <PatientAddressSettings /> </Suspense>} />

        </Route>
      </Routes>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000,
          success: {
            duration: 3000,
          },
          error: {}
        }}
      />
    </ThemeProvider>
  );
}
export default App;
