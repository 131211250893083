import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from 'store/store';
import { ApolloProvider } from '@apollo/client';
// import { client } from 'graphql/client';
import { testClient } from 'graphql/testClient';
import FallbackRender from 'components/FallbackRender';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={testClient}>
        <BrowserRouter basename="/">
          <ErrorBoundary fallbackRender={FallbackRender}>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();