import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";

export const patientApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "patientApi",
  tagTypes: ['HealthRecords'],
  endpoints: (build) => ({
    getServices: build.query({
      query() {
        return {
          url: `/api/patient/user_groups/`,
          method: "GET"
        };
      },
    }),
    createHealthRecord: build.mutation({
      query(body) {
        return {
          url: `/api/health_records/`,
          method: "POST",
          body
        };
      },
      invalidatesTags: ['HealthRecords'],
    }),
    getHealthRecords: build.query({
      query({ query = '', page }) {
        return {
          url: `api/health_records/?keywords=${query}&page_size=5&offset=${page * 5}`,
          method: "GET"
        };
      },
      providesTags: (result) =>
        result?.data?.documents
          ? [...result?.data?.documents?.map(({ id }) => ({ type: 'HealthRecords', id })), { type: 'HealthRecords', id: 'LIST' }]
          : [{ type: 'HealthRecords', id: 'LIST' }],
    }),
    deleteHealthRecord: build.mutation({
      query(id) {
        return {
          url: `/api/health_records/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (p1, p2, id) => [
        // { type: 'HealthRecords', id },
        { type: 'HealthRecords', id: 'LIST' }
      ],
    }),
    UpdateHealthRecord: build.mutation({
      query({ body, id }) {
        return {
          url: `/api/health_records/my_health_records/${id}/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: (p1, p2, { id }) => [{ type: 'HealthRecords', id }],
    }),
    updateIntakForm: build.mutation({
      query({ body, id }) {
        return {
          url: `/api/intake/${id}/`,
          method: "PATCH",
          body
        };
      },
    }),
    getHealthArticles: build.query({
      query({ search = '', category = '' }) {
        return {
          url: `/api/patient_engagement/health_resources/?category=${category}&search=${search}`,
          method: "GET",
        };
      },
    }),
    trackRedirection: build.mutation({
      query(id) {
        return {
          url: `/api/patient_engagement/health_resources/${id}/track_redirection_event/`,
          method: "POST",
        };
      },
    }),
    addArticleReview: build.mutation({
      query(body) {
        return {
          url: `/api/patient_engagement/health_resources_review/`,
          method: "POST",
          body
        };
      },
    }),
    getHealthPlans: build.query({
      query() {
        return {
          url: `/api/consultation/health_plans/`,
          method: "GET"
        };
      },
    }),
    getChatDetails: build.query({
      query(id) {
        return {
          url: `/api/consultation/chats/${id}/`,
          method: "GET"
        };
      },
      transformResponse: (response) => response?.data?.conversation
    }),
    getMedication: build.query({
      query() {
        return {
          url: `/api/consultation/medications/`,
          method: "GET"
        };
      },
      transformResponse: (response) => response?.data?.medications ?? []
    }),
  }),
});

export const {
  useGetServicesQuery,
  useCreateHealthRecordMutation,
  useGetHealthRecordsQuery,
  useDeleteHealthRecordMutation,
  useUpdateHealthRecordMutation,
  useUpdateIntakFormMutation,
  useGetHealthArticlesQuery,
  useTrackRedirectionMutation,
  useAddArticleReviewMutation,
  useGetHealthPlansQuery,
  useGetChatDetailsQuery,
  useGetMedicationQuery,
} = patientApi;
