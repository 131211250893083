import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const HealthArticlesLogo = ({ active = false }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.30009 8.25H18.7001M3.30009 13.75H18.7001M10.5416 2.75C8.99732 5.22464 8.17861 8.08304 8.17861 11C8.17861 13.917 8.99732 16.7754 10.5416 19.25M11.4583 2.75C13.0026 5.22464 13.8213 8.08304 13.8213 11C13.8213 13.917 13.0026 16.7754 11.4583 19.25M2.75 11C2.75 12.0834 2.96339 13.1562 3.37799 14.1571C3.7926 15.1581 4.40029 16.0675 5.16637 16.8336C5.93245 17.5997 6.84193 18.2074 7.84286 18.622C8.8438 19.0366 9.91659 19.25 11 19.25C12.0834 19.25 13.1562 19.0366 14.1571 18.622C15.1581 18.2074 16.0675 17.5997 16.8336 16.8336C17.5997 16.0675 18.2074 15.1581 18.622 14.1571C19.0366 13.1562 19.25 12.0834 19.25 11C19.25 8.81196 18.3808 6.71354 16.8336 5.16637C15.2865 3.61919 13.188 2.75 11 2.75C8.81196 2.75 6.71354 3.61919 5.16637 5.16637C3.61919 6.71354 2.75 8.81196 2.75 11Z" stroke={active ? appPrimaryColor : appGreyColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default HealthArticlesLogo